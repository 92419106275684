<template>
  <div class="animated fadeIn" style="min-height: 1500px; background-color: white; margin-bottom: 30px">
    <b-row style="margin: 0px; border-radius: 5px; height: 50px;">
      <b-col cols="12">
        <b-row style="margin-top: 10px; margin-bottom: 10px;">
          <b-col sm="12" md="9">
            <span style="color: #50afc9; font-size: 16px; white-space: nowrap;">
              {{ d_allFlowingText.text.substring(d_allFlowingText.text.length - d_allFlowingText.index, d_allFlowingText.text.length) }}
            </span>
          </b-col>
          <b-col sm="12" md="3">
            <b-form-group label="" label-for="search_patient">
              <b-input-group>
                <b-form-input id="wisdomera_ai" :placeholder="$t('wdm16.10985')" type="text" style="color: black;" v-model="d_searchWisdomEraAiRegion" @keydown.enter.native="ClickEnter"></b-form-input>
                <b-input-group-append>
                  <b-button variant="secondary" @click="f_searchWaim()"><i class="fa fa-search"></i> </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row v-if="d_wdm22" style="background-color: white; margin: 0px;">
      <b-col sm="12" md="3">
        <b-row style="margin: 0px; border-radius: 5px;">
          <b-col sm="12" md="4">
            <template v-if="d_profileImage">
              <img :src="d_profileImage" style="width: 100px; height: 100px; border-radius: 50px; border: solid 2px #50afc9;">
            </template>
            <template v-else>
              <img src="../../../static/img/favicon.png" style="height: 50px; width: 50px; border-radius: 10px;">
            </template>
          </b-col>
          <b-col sm="12" md="8" style="text-align: center;">
            <div style="white-space: nowrap;">
              <span style="font-size: 50px; color: #50afc9; font-family: 'Palatino Linotype', 'Book Antiqua', Palatino, serif;">
                {{d_wdm22.data.general.name.val[0]}}
              </span>
              <span style="margin-left: -15px; font-size: 16px; color: #50afc9; font-family: 'Palatino Linotype', 'Book Antiqua', Palatino, serif;">
                {{d_wdm22.data.general.name.val.substring(1, d_wdm22.data.general.name.val.length)}}
              </span>
            </div>
            <div>
              <template v-if="d_wdm22.data.general.facebook && d_wdm22.data.general.facebook.val">
                <a :href="d_wdm22.data.general.facebook.val" target="_target" style="margin-right: 10px;"><i class="fa fa-facebook"> </i></a>
              </template>
              <template v-else>
                <a href="javascript:;" title="Henüz tanımlanmamış" style="margin-right: 10px; color: #787c80;"><i class="fa fa-facebook"> </i></a>
              </template>
              <template v-if="d_wdm22.data.general.instagram && d_wdm22.data.general.instagram.val">
                <a :href="d_wdm22.data.general.instagram.val" target="_target" style="margin-right: 10px;"><i class="fa fa-instagram"> </i></a>
              </template>
              <template v-else>
                <a href="javascript:;" title="Henüz tanımlanmamış" style="margin-right: 10px; color: #787c80;"><i class="fa fa-instagram"> </i></a>
              </template>
              <template v-if="d_wdm22.data.general.twitter && d_wdm22.data.general.twitter.val">
                <a :href="d_wdm22.data.general.twitter.val" target="_target" style="margin-right: 10px;"><i class="fa fa-twitter"> </i></a>
              </template>
              <template v-else>
                <a href="javascript:;" title="Henüz tanımlanmamış" style="margin-right: 10px; color: #787c80;"><i class="fa fa-twitter"> </i></a>
              </template>
              <template v-if="d_wdm22.data.general.linkedin && d_wdm22.data.general.linkedin.val">
                <a :href="d_wdm22.data.general.linkedin.val" target="_target" style="margin-right: 10px;"><i class="fa fa-linkedin"> </i></a>
              </template>
              <template v-else>
                <a href="javascript:;" title="Henüz tanımlanmamış" style="margin-right: 10px; color: #787c80;"><i class="fa fa-linkedin"> </i></a>
              </template>
              <template v-if="d_wdm22.data.general.youtube && d_wdm22.data.general.youtube.val">
                <a :href="d_wdm22.data.general.youtube.val" target="_target" style="margin-right: 10px;"><i class="fa fa-youtube"> </i></a>
              </template>
              <template v-else>
                <a href="javascript:;" title="Henüz tanımlanmamış" style="margin-right: 10px; color: #787c80;"><i class="fa fa-youtube"> </i></a>
              </template>
              <template v-if="d_wdm22.data.general.website && d_wdm22.data.general.website.val">
                <a :href="d_wdm22.data.general.website.val" target="_target" style="margin-right: 10px;"><i class="fa fa-globe"> </i></a>
              </template>
              <template v-else>
                <a href="javascript:;" title="Henüz tanımlanmamış" style="margin-right: 10px; color: #787c80;"><i class="fa fa-globe"> </i></a>
              </template>
            </div>
          </b-col>
        </b-row>
        <b-row style="height: 20px;">
          <b-col cols="12" style="text-align: right;">
          </b-col>
        </b-row>
        <b-card>
          <b-card-header header-bg-variant="white" class="p-1" header-text-variant="dark">
            <b-row>
              <b-col sm="12" md="12">
                <img src="@/icon/3070933.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 3em;" /> Deneyimler
              </b-col>
            </b-row>
          </b-card-header>
          <template v-if="d_wdm22.data.general.institutions && d_wdm22.data.general.institutions.list">
            <template v-for="(ins, ins_ind) in d_wdm22.data.general.institutions.list">
              <b-row style="margin: 0px; border-radius: 5px;">
                <b-col cols="12">
                  <b-dropdown v-if="d_isEligibleEdit" variant="white" style="margin-right: 10px;">
                    <template slot="button-content">
                      <img src="@/icon/969174.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" />
                    </template>
                    <b-dropdown-item @click="f_institutionDelete(ins_ind)">sil</b-dropdown-item>
                    <b-dropdown-item @click="f_addEditInstitution('edit', ins_ind)">düzenle</b-dropdown-item>
                  </b-dropdown>
                  <img src="@/icon/3070933.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" /> <span>{{ ins.institution_name.val }}</span>
                </b-col>
              </b-row>
            </template>
          </template>
          <template v-if="d_isEligibleEdit && (!d_wdm22.data.general.institutions || (d_wdm22.data.general.institutions && d_wdm22.data.general.institutions.list.length < 10))">
            <b-row style="margin: 0px; border-radius: 5px; height: 50px;">
              <b-col cols="12">
                <b-button @click="f_addEditInstitution()" variant="white" title="Kurum eklemek için tıklayınız">
                  <img src="@/icon/3070933.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" /> +
                </b-button>
              </b-col>
            </b-row>
          </template>
        </b-card>
        <b-card>
          <b-card-header header-bg-variant="white" class="p-1" header-text-variant="dark">
            <b-row>
              <b-col sm="12" md="12">
                <img src="@/icon/1866973.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 3em;" /> Sayfa
              </b-col>
            </b-row>
          </b-card-header>
          <b-row :style="d_selectedTab === 'dashboard' ? 'margin: 0px; border-radius: 5px; background: #c8e8f1; font-weight: bold; padding: 10px; cursor: pointer;' : 'margin: 0px; border-radius: 5px; padding: 10px; cursor: pointer;'" @click="f_selectTab('dashboard')">
            <b-col cols="12">
              <img src="@/icon/969174.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" /> Anasayfa
            </b-col>
          </b-row>
          <b-row :style="d_selectedTab === 'wai_products' ? 'margin: 0px; border-radius: 5px; background: #c8e8f1; font-weight: bold; padding: 10px; cursor: pointer;' : 'margin: 0px; border-radius: 5px; padding: 10px; cursor: pointer;'" @click="f_selectTab('wai_products')">
            <b-col cols="12">
              <img src="@/icon/2324511.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" /> Wai Ürünleri
            </b-col>
          </b-row>
          <b-row :style="d_selectedTab === 'profile_messages' ? 'margin: 0px; border-radius: 5px; background: #c8e8f1; font-weight: bold; padding: 10px;  cursor: pointer;' : 'margin: 0px; border-radius: 5px; padding: 10px; cursor: pointer;'" @click="f_selectTab('profile_messages')">
            <b-col cols="12">
              <img src="@/icon/236883.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" /> Profil Mesajları
            </b-col>
          </b-row>
          <b-row :style="d_selectedTab === 'contact' ? 'margin: 0px; border-radius: 5px; background: #c8e8f1; font-weight: bold; padding: 10px;  cursor: pointer;' : 'margin: 0px; border-radius: 5px; padding: 10px; cursor: pointer;'" @click="f_selectTab('contact')">
            <b-col cols="12">
              <img src="@/icon/1753831.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" /> İletişim
            </b-col>
          </b-row>
          <b-row v-if="d_isEligibleEdit" :style="d_selectedTab === 'settings' ? 'margin: 0px; border-radius: 5px; background: #c8e8f1; font-weight: bold; padding: 10px;  cursor: pointer;' : 'margin: 0px; border-radius: 5px; padding: 10px; cursor: pointer;'" @click="f_selectTab('settings')">
            <b-col cols="12">
              <img src="@/icon/1115721.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" /> Ayarlar
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col sm="12" md="6">
        <b-row>
          <b-col sm="12" md="9">
          </b-col>
          <b-col sm="12" md="3" style="text-align: right;">
            <template v-if="d_isEligibleEdit">
              <b-dropdown variant="white">
                <template slot="button-content">
                  <img src="@/icon/969174.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" />
                </template>
                <b-dropdown-item @click="f_aboutMe('new')">yeni hakkımda</b-dropdown-item>
              </b-dropdown>
            </template>
          </b-col>
        </b-row>
        <b-row :style="d_isEligibleEdit ? 'text-align: center; border: dotted 1px black; margin-bottom: 10px;' : 'text-align: center; margin-bottom: 10px;'">
          <b-col sm="12">
            <template v-if="d_wdm22.data && d_wdm22.data.general.cover_photo && d_wdm22.data.general.cover_photo.val">
              <img :src="d_wdm22.data.general.cover_photo.val" style="width: 100%; max-height: 300px;">
            </template>
            <template v-else>
              <img src="../../../static/img/favicon.png" style="height: 300px;">
            </template>
          </b-col>
        </b-row>
        <b-row v-if="d_isEligibleEdit" style="margin-bottom: 10px;">
          <b-col sm="12" md="8" style="text-align: right;">
          </b-col>
          <b-col sm="12" md="4">
            <b-form-file @input="f_addImageToSelectedParameter()" v-model="d_selectedImage" accept="jpeg,jpg,png" placeholder="Kapak resmi yükle" :drop-placeholder="$t('wdm16.12989')"></b-form-file>
          </b-col>
        </b-row>
        <template v-if="['dashboard', 'wai_products'].indexOf(d_selectedTab) !== -1">
          <template v-if="d_waiList.length > 0">
            <wai-list :p_waiList="d_waiList"></wai-list>
          </template>
        </template>
        <template v-if="['dashboard', 'profile_messages'].indexOf(d_selectedTab) !== -1">
          <template v-if="d_wdm22.data.general.about_me && d_wdm22.data.general.about_me.list.length > 0">
            <template v-for="(about_me, about_me_ind) in d_wdm22.data.general.about_me.list">
              <div style="white-space: pre-line; border: dotted 1px #16d1fa; padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px green; margin-bottom: 10px;">
                <b-row>
                  <b-col sm="12" md="6">
                    <img src="@/icon/236883.png" style="width: 2em;" title="Profil Mesajı">
                  </b-col>
                  <b-col sm="12" md="6" style="text-align: right;">
                    <template v-if="d_isEligibleEdit">
                      <b-dropdown variant="white">
                        <template slot="button-content">
                          <img src="@/icon/969174.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" />
                        </template>
                        <b-dropdown-item @click="f_aboutMe('edit', about_me_ind)">düzenle</b-dropdown-item>
                        <b-dropdown-item @click="f_aboutMe('new')">yeni hakkımda</b-dropdown-item>
                      </b-dropdown>
                    </template>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="12" md="12" style="text-align: center;">
                    <img :src="about_me.image.val" style="max-width: 50%; max-height: 300px;">
                  </b-col>
                </b-row>
                <b-row style="margin: 10px;">
                  <b-col sm="12" md="12">
                    {{ about_me.note.val }}
                  </b-col>
                </b-row>
              </div>
            </template>
          </template>
        </template>
        <template v-if="['settings'].indexOf(d_selectedTab) !== -1 && d_isEligibleEdit">
          <b-row style="height: 30px;">
            <b-col sm="12" md="12"></b-col>
          </b-row>
          <b-card no-body>
            <b-card-header header-bg-variant="secondary" class="p-1" header-text-variant="dark">
              <b-row>
                <b-col sm="12" md="6">
                  <strong>Bağlantılarım</strong>
                </b-col>
                <b-col sm="12" md="6" style="text-align: right;">
                  <template>
                    <b-dropdown>
                      <template slot="button-content">
                        <img src="@/icon/969174.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" />
                      </template>
                      <b-dropdown-item @click="f_saveSettings()">{{ $t('wdm16.4061') }}</b-dropdown-item>
                    </b-dropdown>
                  </template>
                </b-col>
              </b-row>
            </b-card-header>
            <b-row style="margin: 5px; color: #50afc9;">
              <b-col sm="12" md="4"> <i class="fa fa-twitter"> </i> Twitter </b-col>
              <b-col sm="12" md="8">
                <b-form-input v-model="d_settings.twitter"></b-form-input>
              </b-col>
            </b-row>
            <b-row style="margin: 5px; color: #50afc9;">
              <b-col sm="12" md="4"> <i class="fa fa-facebook"> </i> Facebook </b-col>
              <b-col sm="12" md="8">
                <b-form-input v-model="d_settings.facebook"></b-form-input>
              </b-col>
            </b-row>
            <b-row style="margin: 5px; color: #50afc9;">
              <b-col sm="12" md="4"> <i class="fa fa-linkedin"> </i> Linkedin </b-col>
              <b-col sm="12" md="8">
                <b-form-input v-model="d_settings.linkedin"></b-form-input>
              </b-col>
            </b-row>
            <b-row style="margin: 5px; color: #50afc9;">
              <b-col sm="12" md="4"> <i class="fa fa-instagram"> </i> Instagram </b-col>
              <b-col sm="12" md="8">
                <b-form-input v-model="d_settings.instagram"></b-form-input>
              </b-col>
            </b-row>
            <b-row style="margin: 5px; color: #50afc9;">
              <b-col sm="12" md="4"> <i class="fa fa-youtube"> </i> Youtube </b-col>
              <b-col sm="12" md="8">
                <b-form-input v-model="d_settings.youtube"></b-form-input>
              </b-col>
            </b-row>
            <b-row style="margin: 5px; color: #50afc9;">
              <b-col sm="12" md="4"> <i class="fa fa-globe"> </i> Website </b-col>
              <b-col sm="12" md="8">
                <b-form-input v-model="d_settings.website"></b-form-input>
              </b-col>
            </b-row>
          </b-card>
        </template>
      </b-col>
      <b-col v-if="$route.name !== 'user_settings'" sm="12" md="3">
        <template v-if="!device.isMobile">
          <b-card>
            <b-card-header header-bg-variant="white" class="p-1" header-text-variant="dark">
              <b-row>
                <b-col sm="12" md="12">
                  <img src="@/icon/2324511.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 3em;" /> Önerilen wai ürünleri
                </b-col>
              </b-row>
            </b-card-header>
            <wai-list :p_waiList="d_waiData.wai"></wai-list>
          </b-card>
        </template>
      </b-col>
    </b-row>
    <modal v-if="d_aboutMe.show" @close="d_aboutMe.show = false" :width="'750'">
      <h3 slot="header">
        Profil Mesajı
      </h3>
      <div slot="body">
        <b-row>
          <b-col sm="12">
            <b-form-file @input="f_addImagetoAboutMe()" v-model="d_aboutMe.selected_image" accept="jpeg,jpg,png" placeholder="Resim yükle" :drop-placeholder="$t('wdm16.12989')">
            </b-form-file>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12">
            <template v-if="d_aboutMe.selected_image">
              <img :src="d_aboutMe.selected_image" style="width: 300px;">
            </template>
          </b-col>
        </b-row>
        <b-row style="margin-top: 50px;">
          <b-col sm="12">
            <b-textarea v-model="d_aboutMe.text" placeholder="Lütfen paylaşmak istediğiniz bilgileri yazınız." rows="5" style="width: 100%;"></b-textarea>
          </b-col>
        </b-row>
      </div>
      <div slot="footer">
        <b-button variant="danger" @click="d_aboutMe.show = false" style="margin-right: 5px;">{{ $t('wdm16.4053') }}</b-button>
        <b-button variant="success" @click="f_aboutMeSave()" style="margin-right: 5px;">{{ $t('wdm16.4061') }}</b-button>
      </div>
    </modal>
    <modal v-if="d_institution.show" @close="d_institution.show = false" :width="'500'">
      <h3 slot="header">
        Kurum Ekranı
      </h3>
      <div slot="body">
        <b-row style="margin-top: 50px;">
          <b-col sm="12">
            <b-textarea v-model="d_institution.text" placeholder="Lütfen paylaşmak istediğiniz bilgileri yazınız." rows="5" style="width: 100%;"></b-textarea>
          </b-col>
        </b-row>
      </div>
      <div slot="footer">
        <b-button variant="danger" @click="d_institution.show = false" style="margin-right: 5px;">{{ $t('wdm16.4053') }}</b-button>
        <b-button variant="success" @click="f_saveInstitution()" style="margin-right: 5px;">{{ $t('wdm16.4061') }}</b-button>
      </div>
    </modal>
  </div>
</template>

<script>
import {
  default as WaiList
} from '@/components/widgets/WaiList';
import {
  default as Modal
} from '@/components/widgets/Modal';
import PoleStarService from '@/services/polestar';
import WaiService from '@/services/wai';
import moment from 'moment';
import { mapGetters } from 'vuex';
export default {
  name: 'Profile',
  computed: mapGetters({
    lang: 'lang',
    device: 'device'
  }),
  props: {},
  components: {
    WaiList,
    Modal
  },
  data () {
    return {
      d_allFlowingText: {
        'text': '                            Merhaba, WisdomEra ile yep yeni bir yapay zeka profiline hoşgeldiniz.',
        'index': 0
      },
      d_flowingText: '',
      d_settings: {
        'twitter': '',
        'facebook': '',
        'youtube': '',
        'linkedin': '',
        'website': '',
        'instagram': ''
      },
      d_profileImage: '',
      d_searchWisdomEraAiRegion: '',
      d_selectedTab: 'dashboard',
      d_aboutMe: {
        'image_info': {},
        'show': false,
        'type': '',
        'selected_image': '',
        'selected_index': '',
        'text': ''
      },
      d_institution: {
        'show': false,
        'type': '',
        'text': ''
      },
      user: '',
      d_wdm22: '',
      d_isEligibleEdit: false,
      d_waiList: [],
      d_nextWaiInterval: 0,
      d_waiData: {
        'last_wai': '0',
        'wai': [],
        'count': 5,
        'published_dmp_count': 0
      },
      d_selectedImage: '',
      d_username: '',
      d_flowingTextInterval: 0
    };
  },
  created: function () {
    this.user = JSON.parse(localStorage.getItem('user'));
  },
  mounted: function () {
    this.d_wdm22 = '';
    this.f_getUserProfile();
    if (this.$route.name !== 'user_settings') {
      this.f_getNextWai();
      this.f_getNextWaiStartInterval();
    }
    if (this.$route.query && this.$route.query.page) {
      this.d_selectedTab = this.$route.query.page;
    }
    this.f_startFlowingText();
  },
  destroyed () {
    clearInterval(this.d_nextWaiInterval);
    clearInterval(this.d_flowingTextInterval);
    // clearInterval(this.d_nextDrawAlgorithm);
  },
  methods: {
    f_institutionDelete: function (ins_ind) {
      this.d_wdm22.data.general.institutions.list.splice(ins_ind, 1);
      this.f_saveWdm22();
    },
    f_saveInstitution: function () {
      if (this.d_institution.type === 'edit') {
        this.d_wdm22.data.general.institutions.list[this.d_institution.ins_ind].institution_name = { 'val': this.d_institution.text };
      } else {
        if (!this.d_wdm22.data.general.institutions) {
          this.d_wdm22.data.general.institutions = { 'list': [] };
        }
        this.d_wdm22.data.general.institutions.list.push({ 'institution_name': { 'val': this.d_institution.text } });
      }
      this.f_saveWdm22();
    },
    f_addEditInstitution: function (type, ins_ind) {
      this.d_institution = {
        'show': true,
        'type': type,
        'text': '',
        'ins_ind': ''
      };
      if (type === 'edit') {
        this.d_institution.text = this.d_wdm22.data.general.institutions.list[ins_ind].institution_name.val;
        this.d_institution.ins_ind = ins_ind;
      }
    },
    f_startFlowingText: function () {
      this.d_flowingTextInterval = setInterval(function () {
        if (this.d_allFlowingText.index !== this.d_allFlowingText.text.length) {
          this.d_allFlowingText.index += 1;
        } else {
          this.d_allFlowingText.index = 0;
        }
      }.bind(this), 100);
    },
    f_saveSettings: function () {
      this.d_wdm22.data.general.twitter = { 'val': this.d_settings.twitter };
      this.d_wdm22.data.general.instagram = { 'val': this.d_settings.instagram };
      this.d_wdm22.data.general.facebook = { 'val': this.d_settings.facebook };
      this.d_wdm22.data.general.youtube = { 'val': this.d_settings.youtube };
      this.d_wdm22.data.general.website = { 'val': this.d_settings.website };
      this.d_wdm22.data.general.linkedin = { 'val': this.d_settings.linkedin };
      this.f_saveWdm22();
    },
    f_searchWaim: function () {
      let data = { 'search': this.d_searchWisdomEraAiRegion };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Yapay zeka modellerinde arama işlemi başlatıldı. Lütfen bekleyiniz.' } });
      PoleStarService.search_waim(data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status === 'success') {
            this.d_searchWaimList = resp.data.result.list;
            if (this.d_searchWaimList.length > 0) {
              let route_data = {
                'name': 'polestarwaim',
                'path': '/whsmodules/polestar/waim',
                // 'params': this.d_searchWaimList => does not work
                'query': { 'waim_text': this.d_searchWisdomEraAiRegion }
              };
              this.$router.push(route_data);
              // this.d_showWaimListModal = true;
            } else {
              alert('Belirttiğiniz kriterlere uygun yapay zeka modeli bulunamadı.');
            }
          } else {
            alert('Error :' + resp.data.message)
          }
        });
    },
    ClickEnter: function () {
      if (event.which === 13) {
        this.f_searchWaim();
      }
    },
    f_selectTab: function (type) {
      if ((this.$route.query.page && this.$route.query.page !== type) || !this.$route.query.page) {
        this.d_selectedTab = type;
        let route_data = {
          'name': 'waiprofile',
          'path': '/p/',
          'params': { 'username': this.d_username },
          'meta': {
            'wai': 1
          },
          'query': {
            'page': type
          }
        };
        this.$router.push(route_data);
        this.$forceUpdate();
      }
    },
    f_aboutMe: function (type, about_me_ind = '') {
      this.d_aboutMe.type = type;
      this.d_aboutMe.image_info = {};
      this.d_aboutMe.selected_index = about_me_ind;
      this.d_aboutMe.selected_image = '';
      this.d_aboutMe.text = '';
      if (type === 'edit') {
        this.d_aboutMe.selected_index = about_me_ind;
        try {
          this.d_aboutMe.image_info = this.d_wdm22.data.general.about_me.list[about_me_ind].image.image_info;
        } catch (err) {}
        try {
          this.d_aboutMe.selected_image = this.d_wdm22.data.general.about_me.list[about_me_ind].image.val;
        } catch (err) {}
        try {
          this.d_aboutMe.text = this.d_wdm22.data.general.about_me.list[about_me_ind].note.val;
        } catch (err) {}
      }
      this.d_aboutMe.show = true;
    },
    f_aboutMeSave: function () {
      let about_me_data = {
        'image': {
          'val': this.d_aboutMe.selected_image,
          'image_info': this.d_aboutMe.image_info
        },
        'note': {
          'val': this.d_aboutMe.text
        }
      };
      if (!this.d_wdm22.data.general.about_me) {
        this.d_wdm22.data.general.about_me = { 'list': [] };
      }
      if (this.d_aboutMe.type === 'edit') {
        this.d_wdm22.data.general.about_me.list[this.d_aboutMe.selected_index] = about_me_data;
      } else {
        this.d_wdm22.data.general.about_me.list.push(about_me_data);
      }
      this.d_aboutMe.show = false;
      this.f_saveWdm22();
    },
    f_saveWdm22: function () {
      /*
        let return_data = ClsWdmrForever.turn(this.wisdom_data, this.option_data[this.data_type], ['name', 'delete']);
        let wdmr_name = return_data.name;
        let wdmr_require_alerts = return_data.wdmr_require_alerts;
        let only_one_param_is_filled = return_data.only_one_param_is_filled;
        if (!only_one_param_is_filled) {
          let modal_data = { 'type': 'alert', 'text': 'Veri modelini kaydedebilmek için en az bir veriniz dolu olmalıdır.', 'centered': true, 'title': 'Veri modeli kayıt işlemi zorunlu alanları tamamlayınız.' };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
          return;
        }
        if (wdmr_require_alerts && wdmr_require_alerts.length > 0) {
          let require_txt = '';
          for (let r in wdmr_require_alerts) {
            require_txt += ' * ' + wdmr_require_alerts[r].label;
          }
          let modal_data = { 'type': 'alert', 'text': require_txt, 'centered': true, 'title': 'Veri modeli kayıt işlemi zorunlu alanları tamamlayınız.' };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
          return;
        }
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Görev kayıt işlemi tamamlanıyor.' } });
      */
      let data = {
        'wdm22': this.d_wdm22
      };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'kaydediliyor.' } });
      WaiService.save_wdm22(data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status === 'success') {
            this.d_wdm22 = resp.data.result.wdm22;
            this.d_aboutMe.show = false;
            this.d_institution.show = false;
            this.$forceUpdate();
          } else {
            alert('error ' + resp.data.message);
          }
        });
    },
    f_addImageToSelectedParameter: function () {
      if (this.d_selectedImage) {
        let file_name = this.d_selectedImage.name;
        let arr = this.d_selectedImage.name.split('.');
        let fileExtension = arr[arr.length - 1];
        // console.log(fileExtension);
        if (["jpg", "jpeg", "png"].indexOf(fileExtension.toLowerCase()) === -1) {
          this.d_selectedImage = '';
          this.$forceUpdate();
          alert(this.$t('wdm16.12634'));
          return;
        }
        if (this.d_selectedImage.size > 2097152) {
          this.d_selectedImage = '';
          this.$forceUpdate();
          alert(this.$t('wdm16.12635'));
          return;
        }

        var reader = new FileReader();
        var vm = this;
        let doc_data = {
          'user': this.user.username,
          'information': '',
          'fileExtension': fileExtension,
          'file_name': file_name
        };
        if (!this.d_wdm22.data.general.cover_photo) {
          this.d_wdm22.data.general.cover_photo = {};
        }
        this.d_wdm22.data.general.cover_photo.image_info = doc_data;
        var reader = new FileReader();
        reader.addEventListener("load", function () {
          vm.d_wdm22.data.general.cover_photo.val = reader.result;
          vm.f_saveWdm22();
        }, false);
        reader.readAsDataURL(this.d_selectedImage);
        this.d_selectedImage = '';
      }
    },
    f_addImagetoAboutMe: function () {
      if (this.d_aboutMe.selected_image) {
        let file_name = this.d_aboutMe.selected_image.name;
        let arr = this.d_aboutMe.selected_image.name.split('.');
        let fileExtension = arr[arr.length - 1];
        // console.log(fileExtension);
        if (["jpg", "jpeg", "png"].indexOf(fileExtension.toLowerCase()) === -1) {
          this.d_aboutMe.selected_image = '';
          this.$forceUpdate();
          alert(this.$t('wdm16.12634'));
          return;
        }
        if (this.d_aboutMe.selected_image.size > 2097152) {
          this.d_aboutMe.selected_image = '';
          this.$forceUpdate();
          alert(this.$t('wdm16.12635'));
          return;
        }

        var reader = new FileReader();
        var vm = this;
        let doc_data = {
          'user': this.user.username,
          'information': '',
          'fileExtension': fileExtension,
          'file_name': file_name
        };
        this.d_aboutMe.image_info = doc_data;
        var reader = new FileReader();
        reader.addEventListener("load", function () {
          vm.d_aboutMe.selected_image = reader.result;
        }, false);
        reader.readAsDataURL(this.d_aboutMe.selected_image);
        this.d_aboutMe.selected_image = '';
      }
    },
    f_getNextWai: function () {
      let query = 'last_wai=' + this.d_waiData.last_wai.toString() + '&count=' + this.d_waiData.count.toString();
      WaiService.get_next_wai(query)
        .then(resp => {
          if (resp.data.status === 'success') {
            this.d_waiData.last_wai = resp.data.result.last_wai;
            this.d_waiData.wai = resp.data.result.wai;
          } else {
            console.log('Error :' + resp.data.message);
          }
        });
    },
    f_getNextWaiStartInterval: function () {
      this.d_nextWaiInterval = setInterval(function () {
        this.f_getNextWai();
      }.bind(this), 10000);
    },
    f_goToWaimWdm3Project: function (wdm3_id) {
      let route_data = {
        'name': 'polestarwaimtalk',
        'path': '/whsmodules/polestar/waim/talk',
        'query': { 'waim_id': wdm3_id }
      };
      this.$router.push(route_data);
    },
    f_goToWaimWdm24: function (wdm24_id) {
      let route_data = {
        'name': 'polestarwaimtalkalgorithm',
        'path': '/whsmodules/polestar/waim/talk-algorithm',
        'query': { 'waim_id': wdm24_id }
      };
      this.$router.push(route_data);
    },
    DateFormatWithHourMin: function (date) {
      return moment(date).format('DD/MM/YYYY HH:mm');
    },
    f_getUserProfile: function () {
      if (this.$route.name === 'user_settings') {
        this.d_username = this.user.username;
      } else if (this.$route.params.username) {
        this.d_username = this.$route.params.username;
      }
      // console.log('username ', username);
      if (this.d_username) {
        let query = 'username=' + this.d_username;
        WaiService.get_user_profile(query)
          .then(resp => {
            if (resp.data.status === 'success') {
              this.d_wdm22 = resp.data.result.wdm22;
              this.d_waiList = resp.data.result.wai_list;
              this.d_profileImage = resp.data.result.profile_image;
              if (this.d_wdm22 && this.user && this.user.id === this.d_wdm22.id) {
                this.d_isEligibleEdit = true;
              }
              this.$forceUpdate();
            } else {
              alert('error', resp.data.message);
            }
          });
      }
    }
  },
  watch: {
    'd_selectedTab': function () {
      if (this.d_selectedTab === 'settings' && this.d_wdm22) {
        if (this.d_wdm22.data.general.twitter && this.d_wdm22.data.general.twitter.val) {
          this.d_settings.twitter = this.d_wdm22.data.general.twitter.val;
        }
        if (this.d_wdm22.data.general.facebook && this.d_wdm22.data.general.facebook.val) {
          this.d_settings.facebook = this.d_wdm22.data.general.facebook.val;
        }
        if (this.d_wdm22.data.general.instagram && this.d_wdm22.data.general.instagram.val) {
          this.d_settings.instagram = this.d_wdm22.data.general.instagram.val;
        }
        if (this.d_wdm22.data.general.youtube && this.d_wdm22.data.general.youtube.val) {
          this.d_settings.youtube = this.d_wdm22.data.general.youtube.val;
        }
        if (this.d_wdm22.data.general.website && this.d_wdm22.data.general.website.val) {
          this.d_settings.website = this.d_wdm22.data.general.website.val;
        }
        if (this.d_wdm22.data.general.linkedin && this.d_wdm22.data.general.linkedin.val) {
          this.d_settings.linkedin = this.d_wdm22.data.general.linkedin.val;
        }
      }
    }
  }
};

</script>

