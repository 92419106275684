<template>
  <div>
    <template v-for="(waim, waim_ind) in p_waiList">
      <div style="white-space: pre-line; border: dotted 1px #16d1fa; padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px green; margin-bottom: 10px;">
        <b-row style="margin-bottom: 5px;">
          <b-col sm="12">
            <template v-if="waim.type === 'wdm3'">
              <a href="javascript:;" @click="f_goToWaimWdm3Project(waim.id)">
                <img src="@/icon/2324511.png" class="img-rounded img-responsive" style="width: 2em;" /> {{ waim.name }}
                <small style="color: green;"> ( {{ DateFormatWithHourMin(waim.published_at)  }} ) </small>
                <!-- <b-badge variant="warning">{{ waim.owner }}</b-badge> -->
              </a>
            </template>
            <template v-if="waim.type === 'wdm24'">
              <a href="javascript:;" @click="f_goToWaimWdm24(waim.id)">
                <img src="@/icon/2324511.png" class="img-rounded img-responsive" style="width: 2em;" /> {{ waim.name }}
                <small style="color: green;"> ( {{ DateFormatWithHourMin(waim.published_at)  }} ) </small>
                <!-- <b-badge variant="warning">{{ waim.owner }}</b-badge> -->
              </a>
            </template>
            <template v-if="waim.type === 'user'">
              <a href="javascript:;" @click="f_goToUserProfile(waim)">
                <img src="@/icon/9370.png" class="img-rounded img-responsive" style="width: 2em;" /> {{ waim.username }}
              </a>
            </template>
          </b-col>
        </b-row>
        <b-row :style="device.isMobile ? 'margin-bottom: 20px;' : 'margin-bottom: 20px;'">
          <b-col sm="12">
            <template v-if="waim.type === 'wdm3'">
              <template v-for="(col, col_ind) in waim.column_list">
                {{ col.label }}
                <template v-if="col_ind !== waim.column_list.length - 1">
                  ,
                </template>
              </template>
              parametreleri kullanılarak hazırlanmış bir model.
            </template>
            <template v-if="waim.type === 'wdm24'">
              {{ waim.rule_count }} adet algoritma bulunmaktadır.
            </template>
            <template v-if="waim.information">
              {{ waim.information }}
            </template>
            <template v-if="waim.type === 'user'">
              {{ waim.first_name + ' ' + waim.last_name }} kanalına gitmek için tıklayınız.
            </template>
          </b-col>
        </b-row>
      </div>
    </template>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
export default {
  name: 'WaiList',
  computed: mapGetters({
    lang: 'lang',
    device: 'device'
  }),
  props: {
    p_waiList: {
      type: Array,
      required: true
    }
  },
  components: {},
  data () {
    return {};
  },
  created: function () {},
  mounted: function () {},
  methods: {
    f_goToUserProfile: function (user) {
      let route_data = {
        'name': 'waiprofile',
        'path': '/p/',
        'params': { 'username': user.username },
        'meta': {
          'wai': 1
        }
      };
      this.$router.push(route_data);
    },
    f_goToWaimWdm3Project: function (wdm3_id) {
      let route_data = {
        'name': 'polestarwaimtalk',
        'path': '/whsmodules/polestar/waim/talk',
        'query': { 'waim_id': wdm3_id }
      };
      this.$router.push(route_data);
    },
    f_goToWaimWdm24: function (wdm24_id) {
      let route_data = {
        'name': 'polestarwaimtalkalgorithm',
        'path': '/whsmodules/polestar/waim/talk-algorithm',
        'query': { 'waim_id': wdm24_id }
      };
      this.$router.push(route_data);
    },
    DateFormatWithHourMin: function (date) {
      return moment(date).format('DD/MM/YYYY HH:mm');
    }
  }
};

</script>

